import InputText from './InputText';
import { breakpoint } from '@/helpers/BreakpointHelper';
import React, { forwardRef, InputHTMLAttributes } from 'react';
import styled from 'styled-components';

const RadioTabInput = styled.input`
  display: block;
  z-index: -1;
  height: 1px;
  overflow: hidden;
  color: transparent;
  border: 0;
  padding: 0;
  opacity: 0;
  background: none;
  position: absolute;
  width: 1px;
  margin: 0;
  cursor: pointer;
`;
const RadioLabel = styled.label<{ readOnly?: boolean }>`
  display: inline-block;
  cursor: pointer;
  position: relative;
  ${({ readOnly }) => readOnly && `pointer-events: none;`}
`;

export const RadioTabButton = styled.div<{
  outline?: boolean;
}>`
  display: inline-block;
  text-align: center;
  width: 100%;
  height: 56px;
  line-height: 54px;
  border: 1px solid #1cabe2;
  color: #1cabe2;
  background: transparent;
  transition: background 0.25s, color 0.25s;
  &:hover {
    background: #1cabe2;
    color: #fff;
  }
  & + div {
    margin-left: 32px;
  }
  ${({ outline }) =>
    outline &&
    `
    border: 1px solid #fff;
    color: #fff;
    background: transparent;
  `}

  ${breakpoint(`tablet`)} {
    height: 44px;
    line-height: 42px;
  }
`;

const RadioTabLabel = styled(RadioLabel)<{
  outline?: boolean;
  readOnly?: boolean;
}>`
  max-width: 162px;
  min-width: 100%;
  display: inline-block;
  input[type='radio']:checked + ${RadioTabButton} {
    color: #fff;
    background: #1cabe2;
  }
  input[type='radio'][readonly]
    + ${RadioTabButton},
    input[type='radio']:disabled
    + ${RadioTabButton} {
    color: #b7b8ba;
    border-color: #e5e6e8;
  }
  input[type='radio'][readonly]:checked
    + ${RadioTabButton},
    input[type='radio'][readonly]:checked:disabled
    + ${RadioTabButton} {
    color: #b7b8ba;
    background: #e5e6e8;
    border-color: #e5e6e8;
  }

  ${({ outline }) =>
    outline &&
    `
    input[type="radio"]:checked + ${RadioTabButton} {
      color: #1cabe2;
      background: #fff;
    }
  `}
  ${({ readOnly }) => readOnly && `pointer-events: none;`}
`;
const RadioTabText = styled.span`
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  ${breakpoint(`tablet`)} {
    font-size: 16px;
  }
  ${breakpoint(`mobile`)} {
    font-size: 14px;
  }
`;

const RadioObject = styled.span`
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  border: 1px solid #e5e6e8;
  position: relative;
  vertical-align: middle;
  &::after {
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background: transparent;
    position: absolute;
    left: 5px;
    top: 5px;
  }
  input[type='radio']:checked + & {
    border: 1px solid #1cabe2;
    &::after {
      background: #1cabe2;
    }
  }
  input[type='radio'][readonly]:checked + & {
    border: 1px solid #e5e6e8;
    &::after {
      background: #e5e6e8;
    }
  }
`;
const RadioText = styled.span`
  display: inline-block;
  vertical-align: middle;
  margin-left: 4px;
`;
const RadioInput = styled.input`
  display: block;
  z-index: -1;
  height: 1px;
  overflow: hidden;
  color: transparent;
  border: 0;
  padding: 0;
  opacity: 0;
  background: none;
  position: absolute;
  width: 1px;
  margin: 0;
  cursor: pointer;
`;

interface InputRadioProps extends InputHTMLAttributes<HTMLInputElement> {
  tab?: boolean;
  outline?: boolean;
  label: string | undefined;
}

const InputRadio = forwardRef<HTMLInputElement, InputRadioProps>(
  ({ tab, outline, label, className, ...props }, ref) => {
    const newClassName = `chk-label ${className || ``}`;
    if (tab) {
      return (
        <RadioTabLabel
          outline={outline}
          readOnly={props.readOnly}
          className={newClassName}
        >
          <RadioTabInput {...props} type="radio" ref={ref} />
          <RadioTabButton outline={outline} className="btn">
            <RadioTabText className="txt">{label}</RadioTabText>
          </RadioTabButton>
        </RadioTabLabel>
      );
    }

    return (
      <RadioLabel className={newClassName} readOnly={props.readOnly}>
        <RadioInput {...props} type="radio" ref={ref} />
        <RadioObject />
        <RadioText>{label}</RadioText>
      </RadioLabel>
    );
  },
);

export const InputRadioGroup = styled.div<{ full?: boolean }>`
  display: table;
  width: 100%;
  table-layout: fixed;
  ${({ full }) =>
    full &&
    `
  ${RadioLabel}, ${RadioTabLabel}, ${InputText} {
    display: table-cell;
  }
  ${RadioTabLabel} + ${RadioTabLabel}
  ${RadioTabLabel} + ${RadioTabLabel}{
    ${RadioTabButton} {
      border-left: none;
    }      
  }
  ${RadioLabel} + ${RadioLabel}{
    ${RadioTabButton} {
      border-left: none;
    }      
  }
  `}
`;

export default InputRadio;
